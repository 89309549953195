(<template>
    <div>
        <v-card class="px-2 py-1" elevation="2">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <header class="bb-1 border-color-grey800 mb-10 py-3 d-flex align-items-center justify-space-between">
                            <div>
                                <h2 class="headline mt-0">{{ title }}</h2>
                                <h3 class="body-2 text-grey500 mb-0">
                                    <div class="mt-1">Gäller för {{ sharedWithTypes }}</div>
                                    <div class="mt-1">{{ requiredTypes }}</div>
                                    <div>{{ recommendedTypes }}</div>
                                </h3>
                            </div>
                            <div class="ml-4">
                                <CommentMetadata :category="category" :metadata="metadata"/>
                            </div>
                        </header>

                        <v-alert v-if="hasPreviousComment" text dense type="info" icon="mdi-information-outline" class="mb-8" style="margin-top: -6px;">
                            Det finns noteringar sedan tidigare.
                        </v-alert>

                        <!-- splitta upp när det är jättemånga (gäller auktoriseringar) -->
                        <div v-if="equipments.length > 40">
                            <div class="mb-10 bb-1 border-color-grey800">
                            <v-row v-for="(chunk, index) in checkedChunkedEquipments" :key="index" class="mb-1">
                                <v-col cols="4" v-for="e in chunk" :key="e.bid" class="pb-0 pt-0">
                                    <v-checkbox
                                        :input-value="hasEquipment(e.bid)"
                                        :label="e.name"
                                        :disabled="equipmentIsDisabled(e)"
                                        @change="clicked(e, $event)"
                                    />
                                </v-col>
                            </v-row>
                            </div>
                            <v-row v-for="(chunk, index) in uncheckedChunkedEquipments" :key="index" class="mb-1">
                                <v-col cols="4" v-for="e in chunk" :key="e.bid" class="pb-0 pt-0">
                                    <v-checkbox
                                        :input-value="hasEquipment(e.bid)"
                                        :label="e.name"
                                        :disabled="equipmentIsDisabled(e)"
                                        @change="clicked(e, $event)"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <!-- kolumnisera när det är många -->
                        <div v-else-if="equipments.length > 8">
                            <v-row v-for="(chunk, index) in chunkedEquipments" :key="index" class="mb-1">
                                <v-col cols="4" v-for="e in chunk" :key="e.bid" class="pb-0 pt-0">
                                    <v-checkbox
                                        :input-value="hasEquipment(e.bid)"
                                        :label="e.name"
                                        :disabled="equipmentIsDisabled(e)"
                                        @change="clicked(e, $event)"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <template v-else>
                            <div ma-2 v-for="e in equipments" :key="e.bid" justify="center">
                                <v-checkbox
                                    :input-value="hasEquipment(e.bid)"
                                    :label="e.name"
                                    :disabled="equipmentIsDisabled(e)"
                                    @change="clicked(e, $event)"
                                />
                            </div>
                        </template>

                        <div class="mt-4">
                            <QualityManagerMetadata :sharedtypes="sharedWithTypesIds" :metadata="metadata"/>
                            <ComplaintsManagerMetadata :sharedtypes="sharedWithTypesIds" :metadata="metadata"/>
                            <CalculationsManagerMetadata :sharedtypes="sharedWithTypesIds" :metadata="metadata"/>
                            <NumberMetadata :sharedtypes="sharedWithTypesIds" :metadata="metadata" :category="category.id"/>
                            <PhotoMetadata :metadata="metadata" :category="category.id"/>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <debug-sheet>steg {{qstep}}</debug-sheet>
        <debug-sheet :txt="category">denna kategori</debug-sheet>

    </div>

</template>

<script>

    import {mapGetters, mapMutations, mapState} from 'vuex';
    import DebugSheet from "../../misc/DebugSheet";
    import CommentMetadata from "./QuestionFormComponents/CommentMetadata";
    import CalculationsManagerMetadata from "./QuestionFormComponents/CalculationsManagerMetadata";
    import ComplaintsManagerMetadata from "./QuestionFormComponents/ComplaintsManagerMetadata";
    import QualityManagerMetadata from "./QuestionFormComponents/QualityManagerMetadata";
    import PhotoMetadata from "./QuestionFormComponents/PhotoMetadata";
    import NumberMetadata from "./QuestionFormComponents/NumberMetadata.vue";

    const COMMENT_METADATA_ID = 1;

    export default {
        name: "QuestionForm",
        components: {
            NumberMetadata,
            PhotoMetadata,

            QualityManagerMetadata,
            ComplaintsManagerMetadata, CalculationsManagerMetadata, CommentMetadata, DebugSheet
        },
        props: ['qstep', 'form'],

        computed: {
            category() {
                return this.form.categories[this.qstep - 1];
            },
            title() {
                return this.category && this.category.title;
            },
            isShared() {
                return this.category.types.length > 1;
            },
            sharedWithTypes() {
                return this.category.types
                    .filter(o => this.selectedRevision.types.some(t => t.id === o.id))
                    .map(o => o.short_name).join(", ").toLowerCase();
            },
            sharedWithTypesIds() {
                return this.category.types
                    .filter(o => this.selectedRevision.types.some(t => t.id === o.id))
                    .map(o => o.id);
            },
            equipments() {
                return this.category.equipments;
            },
            checkedChunkedEquipments() {
                return _.chunk(this.equipments.filter(e => this.hasEquipment(e.bid)), 3);
            },
            uncheckedChunkedEquipments() {
                return _.chunk(this.equipments.filter(e => !this.hasEquipment(e.bid)), 3);
            },
            chunkedEquipments() {
                return _.chunk(this.equipments, 3);
            },
            hasMetadata() {
                return this.category.metadata_types.length > 0;
            },
            metadata() {
                return this.category.metadata_types;
            },

            requiredIds() {
                let soft = this.category.ruleSet.find(r => r.mandatory === 1);
                let hard = this.category.ruleSet.find(r => r.mandatory === 2);

                soft = soft && soft.types;
                hard = hard && hard.types;

                return _.intersection([].concat(soft).concat(hard), this.sharedWithTypesIds);
            },

            requiredTypes() {

                let required = this.requiredIds;
                if (required.length === 0) {
                    return "";
                }
                return required.length === this.sharedWithTypesIds.length ? 'Krav för samtliga' : "Krav för " +
                    required.map(o => this.category.types.find(t => t.id === o).short_name).join(", ").toLowerCase();

            },
            recommendedIds() {
                let recommended = this.category.ruleSet.find(r => r.mandatory === 0);
                return _.intersection([].concat(recommended && recommended.types), this.sharedWithTypesIds);
            },
            recommendedTypes() {
                let recommended = this.recommendedIds;
                if (recommended.length === 0) {
                    return "";
                }
                return recommended.length === this.sharedWithTypesIds.length ? 'Rekommendation för samtliga' : "Rekommendation för " +
                    recommended.map(o => this.category.types.find(t => t.id === o).short_name).join(", ").toLowerCase();
            },
            hasPreviousComment() {
                return !!this.previousRevisionMetadata(COMMENT_METADATA_ID, this.category.id)?.json_data?.text
            },
            ...mapState([]),
            ...mapGetters(['selectedRevision', 'selectedRevisionMetadata', 'previousRevisionMetadata']),


        },
        methods: {

            ...mapMutations(['addEquipment', 'removeEquipment']),

            equipmentIsDisabled(equipment){
                return ['toyota', 'lexus', 'suzuki'].includes(equipment?.name?.toLowerCase())
            },
            hasEquipment(id) {
                return this.selectedRevision.equipments.some((o) => {
                    return o.bid == id;
                });
            },
            clicked(equipment, value) {
                if (value) {
                    this.addEquipment(equipment);
                } else {
                    this.removeEquipment(equipment);
                }
            }
        }
    }


</script>

<style scoped>
    .blink {
        color: brown;
        animation-duration: 0.4s;
        animation-name: blink;
        animation-iteration-count: infinite;
        animation-timing-function: steps(2, start);
    }
    @keyframes blink {
        80% {
            visibility: hidden;
        }
    }

</style>

<template>
    <div>
        <v-dialog
            v-model="isFetchingCompanies"
            hide-overlay
            persistent
            eager
            max-width="380"
        >
            <v-card color="secondary" dark>
                <v-card-title class="subtitle-1">Läser in {{ selectedAreaName || '' }}</v-card-title>
                <v-card-text>
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    />
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-container>
            <v-row justify="center" class="mb-4">
                <v-col cols="4" class="text-center">
                    <h2 class="display-1">{{ selectedAreaName || '' }}</h2>
                    <div v-if="numberOfCompanies > 0" class="mt-1 subtitle-1 text-grey500">
                        {{ numberOfCompanies }} företag
                    </div>
                </v-col>
            </v-row>
            <v-row justify="space-between" class="align-items-center">
                <v-col cols="12" sm="5" md="3" style="padding-left: 0; padding-right: 0;">
                    <label id="label-sort-by" class="caption uppercase">Sortera på</label>
                    <v-select
                        v-model="sortBy"
                        :items="sortByItems"
                        item-value="value"
                        item-text="text"
                        label="Sortera på"
                        solo
                        dense
                        class="mt-1"
                        aria-labelledby="label-sort-by"
                    />
                </v-col>
                <v-col cols="12" sm="2" style="padding-left: 0; padding-right: 0;">
                    <v-btn color="primary" outlined small :to="{name: 'areamap'}" class="float-right">
                        Visa på karta
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <div class="content--list">
                    <v-expansion-panels popout v-if="numberOfCompanies > 0">
                        <v-expansion-panel v-for="company in sortedCompanies" :key="company.id">
                            <v-expansion-panel-header>
                                <v-row align="center">
                                    <v-col cols="6">
                                        <div class="d-flex align-items-center">
                                            <h2 class="title mr-2 d-inline-block" v-bind:class="{ 'indigo--text': company.visited_this_year || recentCompanies.includes(company.id) }">
                                                {{ company.company }}
                                            </h2>
                                            <template v-if="isOpenedRevision(company.id) && !isFinishedRevision(company.id)">
                                                <v-icon class="text-xxs mr-2" color="accent">mdi-circle</v-icon>
                                                <span class="text-sm text-grey500 font-normal">
                                                    revision pågår
                                                </span>
                                            </template>
                                        </div>
                                        {{ company.visitort_city }}
                                        <div v-if="company.next_visit" class="mt-3 text-base">
                                            <h4 class="caption mb-1 uppercase">Bokat besök</h4>
                                            {{ company.next_visit }}
                                        </div>
                                    </v-col>
                                    <v-col cols="6">
                                        <span
                                            v-for="activeType in activeTypesForSelectedArea"
                                            :key="activeType.id"
                                        >
                                            <v-chip
                                                class="ma-2"
                                                color="secondary"
                                                v-if="company.types.some(companyType => companyType.id == activeType.id)"
                                            >
                                                {{ activeType.short_name }}
                                            </v-chip>
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="mt-2">
                                    <a :href="googleMapsLink(company)" target="_blank" rel="noopener noreferrer"> {{ company.visitort }}</a>
                                </div>
                                <template v-if="hasAuditManagers(company)">
                                    <h4 class="caption mb-1 mt-4 uppercase">Revisionsansvarig</h4>
                                    <div v-for="(auditManager, auditManagerIndex) in company.audit_managers" :key="auditManagerIndex">
                                        {{ auditManager }}
                                    </div>
                                </template>
                                <v-alert
                                    dense
                                    text
                                    type="warning"
                                    v-if="isFinishedRevision(company.id)"
                                    class="mt-6"
                                >
                                    Den förra revisionen behöver laddas upp innan en ny kan påbörjas.
                                </v-alert>
                                <div class="mt-8 mb-3">
                                    <v-btn color="secondary" outlined :to="{name: 'bookingdetails', params: {companyid: company.id}}" class="mr-8">
                                        {{ company.next_visit ? 'Ändra tid' : 'Boka tid' }}
                                    </v-btn>
                                    <template v-if="!isFinishedRevision(company.id)">
                                        <v-btn color="accent" :to="{name: 'revisionpage', params: {companyid: company.id, step: 0}}">
                                            {{ isOpenedRevision(company.id) ? 'Fortsätt revisionen' : 'Påbörja revision' }}
                                        </v-btn>
                                    </template>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
            </v-row>
        </v-container>
        <v-dialog width="500" v-model="errorModal">
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                    <v-alert class="mt-3"
                        type="error"
                        transition="scale-transition"
                        v-html="errorMessage"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        color="primary"
                        text
                        @click="errorModal = false"
                    >
                        Stäng
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
    import axios from "axios";

    export default {
        name: "MainPageAreaDetails",
        props: [
            'areaid'
        ],
        components: {},
        data: () => ({
            isFetchingCompanies: false,
            errorMessage: false,
            currentLatitude: 0,
            currentLongitude: 0,
            sortBy: 'next_visit',
            sortByItems: [
                { value: 'next_visit', text: 'Bokningsdatum' },
                { value: 'company', text: 'Företagsnamn' },
                { value: 'distance', text: 'Avstånd' }
            ]
        }),
        computed: {
            ...mapState([
                'companies', 'position', 'recentCompanies',
            ]),
            errorModal:{
                get(){
                    return !!this.errorMessage
                },
                set(value){
                    this.errorMessage = false;
                }
            },
            ...mapGetters(['activeTypesForSelectedArea', 'selectedAreaName', 'isOpenedRevision', 'isFinishedRevision']),
            sortedCompanies() {

                switch (this.sortBy) {
                    case 'next_visit':
                        return this.companies;
                    case 'company':
                        return _.cloneDeep(this.companies).sort((a, b) => {
                            return (a.company).localeCompare(b.company);
                        });
                    case 'distance':
                        return _.cloneDeep(this.companies)
                            .map(o => {
                                o.calcdistance = this.distance(o.latitude, o.longitude,
                                    this.currentLatitude, this.currentLongitude);
                                return o;
                            })
                            .sort((a, b) => {
                                return a.calcdistance - b.calcdistance;
                            });
                }
            },
            numberOfCompanies() {
              return _.size(this.companies);
            },
        },

        created: function () {
            this.setSelectedAreaId(this.areaid);
            this.fetchCompaniesForArea(this.areaid);
        },
        watch: {
            areaid() {
                this.setSelectedAreaId(this.areaid);
                this.fetchCompaniesForArea(this.areaid);
            },
            sortBy: {
                // position i store uppdateras hela tiden så sätt lokal position bara när sortering ändras för att
                // inte ladda om länslistan konstant.
                immediate: true,
                handler() {
                    this.currentLatitude = (this.position && this.position.coords) ? this.position.coords.latitude : 0;
                    this.currentLongitude = (this.position && this.position.coords) ? this.position.coords.longitude : 0;
                },
            },
        },

        methods: {
            ...mapMutations([
                'setSelectedAreaId', 'setCompanies'
            ]),
            googleMapsLink(company) {
                // if (company.latitude && company.longitude) {
                //     return `https://www.google.com/maps/search/?api=1&query=${company.latitude},${company.longitude}`;
                // } else {
                    return `https://www.google.com/maps/search/?api=1&query=${company.visitort}`;
                //}
            },
            fetchCompaniesForArea(areaid) {
                this.isFetchingCompanies = true;
                this.setCompanies(null);
                axios.get('/api/company', {params: {area: areaid}})
                    .then(result => {
                        this.setCompanies(result.data);
                    })
                    .catch(error => {
                        this.errorMessage = this.parseServerError(error);
                    })
                    .then(() => {
                        this.isFetchingCompanies = false;
                    })
            },
            hasAuditManagers(company) {
                return _.size(company.audit_managers);
            },
            distance(lat1, lon1, lat2, lon2) {
                if (!(lat1 && lat2 && lon1 && lon2)) { return 0; }

                if ((lat1 === lat2) && (lon1 === lon2)) {
                    return 0;
                } else {
                    let radlat1 = Math.PI * lat1 / 180;
                    let radlat2 = Math.PI * lat2 / 180;
                    let theta = lon1 - lon2;
                    let radtheta = Math.PI * theta / 180;
                    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                    if (dist > 1) {
                        dist = 1;
                    }
                    dist = Math.acos(dist);
                    dist = dist * 180 / Math.PI;
                    dist = dist * 60 * 1.1515;
                    dist = dist * 1.609344;

                    return dist;
                }
            }
        }

    }
</script>

<style scoped>

</style>
